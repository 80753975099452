<template>
    <div id="index">
        <div class="logo">
            <img src="../assets/images/logo.png" alt="">
        </div>
        <div class="con">
            <div class="index-content">
                <div v-for="(item,index) in line" :key="index" class="index-content-line" @click="hukou(item.id)">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
    data() {
        return {
            logoutShow: false,
            line: [
                {
                    name: '农村版',
                    id: 1
                },
                {
                    name: '城市版',
                    id: 2
                }

            ]
        };
    },
    computed: {
    },
    watch: {
        /*'$store.state.mainMenus':(newVal)=>{
            console.log('mainMenus change',newVal)
        }*/
    },
    async mounted() {
        console.log('用户类型', sessionStorage.getItem('usertype'))
        if (sessionStorage.getItem('usertype') !== 'center') {
            this.$router.push('/TerminalLogin')
        }
        if (!sessionStorage.getItem('usertype')) {
            this.$router.push('/Login')
        }
    },
    methods: {
        /**
         * 点击跳转
         * */
        hukou(item) {
            this.$router.push('/');
            this.$store.commit('setHukou', item)
        },
    },
}
</script>

<style scoped lang="scss">
button {
    border: none;
}
#index {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/tp.png') no-repeat;
    background-size: 100% 100%;
}

.logo {
    width: 500px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    position: fixed;
    top:10vh;
    left: 4%;
    img{
        width: 426px;
        height: 85px;
        margin-left: 100px;
    }
}
.con {
    position: fixed;
    top:35vh;
    left: 25%;
}
.index-content {
    width: 1000px;
    height: 320px;
    display: flex;
    justify-content: space-between;
    .index-content-line {
        width: 443px;
        height: 304px;
        background: linear-gradient(#9DB7F7 , #8DC6F7);
        border-radius: 20px;
        font-size: 60px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 304px;
        text-align: center;
        cursor: pointer;
    }
}
</style>
